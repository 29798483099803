<template>
  <div class="bg-cwe" v-loading="loadProfile">
      <div class="container-my" v-if="staffCheck">
          <div class="logins text-center mb-3">
              <img class="bekmega" src="/img/gohrnew.png" alt="company logo" />
              <img class="gohr" src="/img/logo_dark.png" alt="hr logo" />
          </div>
          <div class="">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="8">
                  <div :class="mode ? 'cardtable__day' : 'cardtable__night'" class="card-table p-4 mb-5 text-center" style="position:relative">
                    <div v-if="staff.completion_percentage != 0">
                      <div class="completion_percentage" v-if="staff.completion_percentage <= 100 && staff.completion_percentage >= 75" style="background-color: #12d229">
                        {{staff.completion_percentage}}%
                      </div>
                      <div class="completion_percentage" v-else-if="staff.completion_percentage <= 75 && staff.completion_percentage >= 50" style="background-color: #a1ffac;">
                        {{staff.completion_percentage}}%
                      </div>
                       <div class="completion_percentage" v-else-if="staff.completion_percentage <= 50 && staff.completion_percentage >= 25" style="background-color: #f1c4c4;">
                        {{staff.completion_percentage}}%
                      </div>
                      <div class="completion_percentage" v-else-if="staff.completion_percentage <= 25 && staff.completion_percentage > 0" style="background-color: #b13939b0;">
                        {{staff.completion_percentage}}%
                      </div>
                    </div>
                    <div class="demo-image__preview" >
                      <el-image
                        class="my-c-avatar"
                        style="width: 100px; height: 100px"
                        :src="
                          staff.image ? (baseUrl + staff.image.path) : '/img/avatar.jpeg'
                        "
                        :preview-src-list="[
                          staff.image ? (baseUrl + staff.image.path) : '/img/avatar.jpeg',
                        ]"
                      >
                      </el-image>
                    </div>
                    <!-- <img
                                  :src="
                                      staff.image
                                          ? baseUrl + staff.image.path
                                          : 'img/avatar.jpeg'
                                  "
                                  alt=""
                              /> -->
                    <div :class="mode ? 'text__day2' : 'text__night2'" class="mt-4 left_name">
                      <h3>{{ staff.name + " " + staff.last_name }}</h3>
                    </div>
                  </div>
                  <div :class="mode ? 'cardtable__day' : 'cardtable__night'" class="card-table p-4 pt-5 mb-5">
                    <div  class="itme-info-user co-isir mb-3 pb-2" >
                      <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-office-building"></i>
                      <span :class="mode ? 'text__day2' : 'text__night2'" v-if="staff.company">
                        {{ staff.company.name }}
                      </span>
                    </div>
                    <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
                    <div  class="itme-info-user co-isir mb-3 pb-2">
                      <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-school"></i>
                      <span :class="mode ? 'text__day2' : 'text__night2'"  v-if="staff.branch">
                        {{ staff.branch.name }}
                      </span>
                    </div>
                    <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
                    <div  class="itme-info-user co-isir mb-3 pb-2">
                      <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-suitcase"></i>
                      <span :class="mode ? 'text__day2' : 'text__night2'"  v-if="staff.department">
                        {{ staff.department.name }}
                      </span>
                    </div>
                    <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
                    <div class="itme-info-user co-isir mb-3 pb-2" >
                      <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-position"></i>
                      <span :class="mode ? 'text__day2' : 'text__night2'" v-if="staff.position">
                        {{ staff.position.name }}
                      </span>
                    </div>
                    <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
                    <div class="itme-info-user co-isir mb-3 pb-2">
                      <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-paperclip"></i>
                      <a :class="mode ? 'text__day2' : 'text__night2'">
                        <b v-if="staff.status">
                          <span v-if="staff.status.code == 1" style="color: #67C23A;">
                            {{ staff.status.name }}
                          </span>
                          <span v-else-if="staff.status.code == -1" style="color:#F56C6C;">
                            {{ staff.status.name }}
                          </span>
                          <span v-else style="color:#E6A23C">
                            {{ staff.status.name }}
                          </span>
                        </b>
                      </a>
                    </div>
                    <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
                    <div class="itme-info-user co-isir">
                      <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-phone-outline"></i>
                      <a :class="mode ? 'text__day2' : 'text__night2'" :href="'tel:+' + staff.phone_number">
                        +{{ staff.phone_number }}
                      </a>
                    </div>
                    <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
                    <div class="itme-info-user co-isir">
                      <div  class="text-muted">{{$t("message.last_active")}}</div>
                      <div :class="mode ? 'text__day2' : 'text__night2'">{{ staff.updated_at }}</div>
                    </div>
                  </div>
          
                 
                </el-col>
                <el-col :xs="24" :sm="16">
                  <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
                    <div class="workers-info-title-za">
                      <div class="title">{{$t('message.personal')}}</div>
                    </div>
                    <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
                      <!-- <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div> -->
                      
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold">{{$t("message.last_name")}}:</strong>
                        <span style="text-align: right">{{ staff.last_name }} </span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold">{{$t("message.nameFull")}}:</strong>
                        <span style="text-align: right">{{ staff.name }}</span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold">{{$t("message.email")}}:</strong>
                        <span style="text-align: right">{{ staff.email }}</span>
                      </p>
                      <!-- <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold">Личная эл. почта:</strong>
                        <span style="text-align: right">numonov.akromjon1998@gmail.com</span>
                      </p> -->
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold">{{$t("message.date_of_birth")}}:</strong>
                        <span style="text-align: right">{{ staff.date_of_birth }}</span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold"> {{$t("message.passport_number")}} </strong>
                        <span style="text-align: right">{{ staff.passport_number }}</span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold"> {{$t("message.passport_issued_by")}} </strong>
                        <span style="text-align: right">{{ staff.passport_issued_by }}</span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold"> {{$t("message.passport_valid_until")}} </strong>
                        <span style="text-align: right">{{ staff.passport_valid_until }}</span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold"> {{$t("message.inn")}} </strong>
                        <span style="text-align: right">{{ staff.inn }}</span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold"> {{ $t("message.region") }} </strong>
                        <span style="text-align: right">{{ staff.region ? staff.region.name : "" }}</span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold"> {{ $t("message.district") }} </strong>
                        <span style="text-align: right">{{ staff.district ? staff.district.name : "" }}</span>
                      </p>
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                        <strong class="mr-1 font-bold"> {{ $t("message.address") }} </strong>
                        <span style="text-align: right">{{ staff.address}}</span>
                      </p>
                    </div>
                  </div>
                  <!-- end card-table -->
                  <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'" >
                    <div class="workers-info-title-za">
                      <div class="title">{{ $t("message.contact") }}</div>
                    </div>
                    <div
                        class="workers-info madal-info-workers p-5 pt-2 pb-2"
                        v-loading="loadContact"
                        v-if="staffContact"
                        >
                        <!-- <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div> -->
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.mobil_number") }}:</strong>
                          <span style="text-align: right"><a v-bind:href="'tel:+'+staffContact.personal_phone_number" >{{ staffContact.personal_phone_number }} </a></span>
                        </p>
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.work_phone") }}:</strong>
                          <span style="text-align: right"><a v-bind:href="'tel:+'+staffContact.home_phone_number" >{{ staffContact.home_phone_number }} </a></span>
                        </p>
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.facebook_url") }}:</strong>
                          <span style="text-align: right">{{ staffContact.facebook }} </span>
                        </p>
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.twitter_url") }}:</strong>
                          <span style="text-align: right">{{ staffContact.twitter }} </span>
                        </p>
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.instagram_url") }}:</strong>
                          <span style="text-align: right"><a v-bind:href="staffContact.instagram" target="_blank">{{ $t("message.instagram_url") }}</a></span>
                        </p>
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.linkedIn_url") }}:</strong>
                          <span style="text-align: right">{{ staffContact.linkedin }}</span>
                        </p>
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.telegram_username") }}:</strong>
                          <span style="text-align: right"><a target="_blank" v-bind:href="'https://telegram.me/'+staffContact.telegram">{{ staffContact.telegram }}</a></span>
                        </p>
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.tiktok_username") }}:</strong>
                          <span style="text-align: right">{{ staffContact.tiktok }}</span>
                        </p>
                        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                          <strong class="mr-1 font-bold">{{ $t("message.email") }}:</strong>
                          <span style="text-align: right"><a target="_blank" v-bind:href="'mailto:'+staffContact.email">{{ staffContact.email }}</a></span>
                        </p>
                  </div>
                  </div>
                  <!-- end card-table -->
                </el-col>
              </el-row>
          </div>
          <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
              <div class="workers-info-title-za">
                  <div :class="mode ? 'text__day2' : 'text__night2'" class="title">{{$t('message.report_card')}}</div>
                  <el-date-picker
                  :class="mode ? 'input__day' : 'input__night'"
                    size="medium"
                    v-model="select_date"
                    type="month"
                    :format="'MMMM yyyy'"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
              </div>
              <el-row :gutter="20" v-if="staff.work_mounth_plane">
                <el-col :xs="24" :sm="6" class="crmhome__scroll">
                    <div class="ml-3 mt-5 crmhome__box">
                        <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                            <div class="text-center">
                                <span :class="mode ? '' : 'textmuted__night'">{{$t('message.wage')}}</span>
                            </div>
                            <div class="text-center mt-3 profile__title" >
                                <b :class="mode ? 'text__day2' : 'text__night2'">{{Number(staff.activeSalaries).toLocaleString()}} сум</b>
                            </div>
                        </el-card>

                        <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                            <div class="text-center">
                                <span :class="mode ? '' : 'textmuted__night'">{{$t("message.limit_work_time")}}</span>
                            </div>
                            <div class="text-center mt-3 profile__title" v-if="staff_list&&staff_list.work_mounth_plane">
                                <b :class="mode ? 'text__day2' : 'text__night2'">{{Math.floor(staff_list.work_mounth_plane.limit.current_limit)}} {{$t('message.hour')}} {{Math.round((staff_list.work_mounth_plane.limit.current_limit - Math.floor(staff_list.work_mounth_plane.limit.current_limit))*60)}} {{$t('message.minut')}}
                                / {{Math.floor(staff_list.work_mounth_plane.limit.mounth_limit)}} {{$t('message.hour')}} {{Math.round((staff_list.work_mounth_plane.limit.mounth_limit - Math.floor(staff_list.work_mounth_plane.limit.mounth_limit))*60)}} {{$t('message.minut')}}
                                <!-- {{staff_list.work_mounth_plane.limit.mounth_limit}} -->
                                </b>
                            </div>
                        </el-card>

                        <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                            <div class="text-center">
                                <span :class="mode ? '' : 'textmuted__night'">{{$t("message.actual_working_time")}}</span>
                            </div>
                            <div class="text-center mt-3 profile__title" v-if="staff_list&&staff_list.work_mounth_plane">
                                <b style="color: #67c23a" v-if="staff_list.work_mounth_plane.work_time>staff_list.work_mounth_plane.limit.current_limit"> {{Math.floor(staff_list.work_mounth_plane.work_time)}} {{$t('message.hour')}} {{Math.round((staff_list.work_mounth_plane.work_time - Math.floor(staff_list.work_mounth_plane.work_time))*60)}} {{$t('message.minut')}} </b>
                                <b style="color: red" v-else-if="(staff_list.work_mounth_plane.work_time>0)"> {{Math.floor(staff_list.work_mounth_plane.work_time)}} {{$t('message.hour')}} {{Math.round((staff_list.work_mounth_plane.work_time - Math.floor(staff_list.work_mounth_plane.work_time))*60)}} {{$t('message.minut')}} </b>
                                <b style="color: red" v-else> {{staff_list.work_mounth_plane.work_time}} {{$t('message.hour')}} </b>
                            </div>
                        </el-card>
                        <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                          <div class="text-center">
                              <span :class="mode ? '' : 'textmuted__night'">{{$t("message.accept_vacation_managment")}}</span>
                          </div>
                          <div class="text-center mt-3 profile__title">
                            <b v-if="staff_list&&staff_list.last_vacation_managment" :class="mode ? 'text__day2' : 'text__night2'">{{staff_list.last_vacation_managment&&staff_list.last_vacation_managment.vacation_month_from?formatMonthDate(staff_list.last_vacation_managment.vacation_month_from):""}}</b>
                            <b v-else :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.not_attached")}}</b>
                          </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="18" class="crmhome__scroll">
                  <div class="mr-3">
                      <table class="my-clendar mb-0 ">
                          <thead>
                              <tr>
                                  <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Monday")}}</div></th>
                                  <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Tuesday")}}</div></th>
                                  <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Wednesday")}}</div></th>
                                  <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Thursday")}}</div></th>
                                  <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Friday")}}</div></th>
                                  <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte" >{{$t("message.Saturday")}}</div></th>
                                  <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte" >{{$t("message.Sunday")}}</div></th>
                              </tr>
                          </thead>
                          
                      </table>
                      <!-- <mounth-report-calendar  :staff_id="staff.id" :work_hour="staff.work_mounth_plane.limit.work_time_this_day"></mounth-report-calendar> -->
                      
                      <div class="my-calendar my-calendar_two" v-if="staff_list&&staff_list.reports && staff_list.reports.daily_reports" v-loading="loadingData">
                        <div class="afte" :class="mode ? 'afte__day' : 'afte__night'"  v-for="n in (staff_list.starts_day_week-1)" :key="'last-'+n" style="grid-column: staff_list.starts_day_week / 7;" >-</div>
                        <div v-for="(staff, index) in staff_list.reports.daily_reports" :key="'index-' + index"  >
                         <el-popover  placement="top-start"
                                          :title="staff.total_working_hour&&staff.total_working_hour!=0?(Math.floor(staff.total_working_hour)+' '+$t('message.hour')+' '+ Math.round((staff.total_working_hour - Math.floor(staff.total_working_hour))*60)+$t('message.minut')):('0 ')+$t('message.hour')"
                                          width="161"
                                          trigger="hover"
                                          :content="'Дата: '+new Date(staff.date).toLocaleString('ru', options)"
                                      >
                                          <div :class="'afte ' +(staff.state=='OPENED'?(staff.is_not_working?'no_working':''):(staff.total_working_hour>0?(staff.total_working_hour>(work_hour?work_hour:8)?'yes':'no'):(staff.is_not_working?'no_working':'off')))"
                                              slot="reference"
                                              @dblclick="showStaffAttendanceInfo(staff)"
                                              :style="staff.is_manual?'color:#fff; background-color:#af4562 !important; border-color: #af4562 !important':''">
                                               {{index+1}}
                                          </div>
                                      </el-popover>
                        
                        </div>
                      </div>
                        <div class="top-filter">
                            <ul class="setting-left-filter">
                                <li>
                                    <span></span>
                                    <p :class="mode ? '' : 'textmuted__night'">{{$t('message.not_coming_day')}} </p>
                                </li>
                                <li>
                                    <span class="yes"></span>
                                    <p :class="mode ? '' : 'textmuted__night'">{{ $t("message.hours_or_more", {
                                        m: work_hour,
                                    })}} </p>
                                </li>
                                <li>
                                    <span class="no"></span>
                                    <p :class="mode ? '' : 'textmuted__night'"> {{ $t("message.less_than_hours", {
                                        m: work_hour,
                                    })}}</p>
                                </li>
                                <li>
                                  <span class="off"></span>
                                  <p :class="mode ? '' : 'textmuted__night'">{{$t('message.not_come_work_day')}}</p>
                                </li>
                                <li>
                                    <span class="no_working"></span>
                                    <p :class="mode ? '' : 'textmuted__night'">{{$t('message.day_absence')}}</p>
                                </li>
                              
                                <li>
                                  <span class="manual-time"></span>
                                  <p :class="mode ? '' : 'textmuted__night'">{{$t('message.manual')}}</p>
                              </li>
                            </ul>
                        </div>
                      </div>
                </el-col>
              </el-row>
          </div>
          <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
              <div class="title">{{$t("message.requirements")}}</div>             
            </div>
            <div
              class="workers-info madal-info-workers p-5 pt-2 pb-2"
              v-loading="loadClientRequirements"
            >
      
              <table class="w-100 td20">
                <tr>
                  <td colspan="2">
                    <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{$t("message.name")}} </strong>
                  </td>
                </tr>
                <tr
                  v-for="(client_requirement, index) in staffClientRequirements"
                  :key="'client_requirement-' + index"
                >
                  <td>
                    <p class="font-medium mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                      <span
                        v-for="(require, index) in client_requirement.requirements"
                        :key="'require' + index"
                      >
                        <p>{{ require.name }}</p>
                        <pre style="font-family: 'M-Regular'; font-size: 14px !important; line-height: 1.5; white-space: break-spaces;     margin-block-start: 1em;
                          margin-block-end: 1em; margin-inline-start: 0px;
                          margin-inline-end: 0px;" v-html="require.description"></pre>
                      </span>
                    </p>
                  </td>                  
                </tr>
              </table>
              <div class="d-flex" style="justify-content: flex-end;">
                <el-button type="warning" size="small" @click="showRequirement(staff.id)" v-if="staff.show_requirement==false">Tanishib chiqish</el-button>
                <el-tag  :type="'success'" class="mx-1"  effect="dark" v-else> Tanishib chiqilgan !</el-tag>

              </div>
            </div>
          </div>
          <staff-bonuses :staff_id="staff.id" v-if="staff" ></staff-bonuses>

          <staff-penalties :staff_id="staff.id" v-if="staff"></staff-penalties>
          
          <profile-take-assent :staff_id="staff.id"></profile-take-assent>
          <!-- end card Изн -->
          
          <advance-salary :staff_id="staff.id"></advance-salary>
          <!-- end card Аванс -->
    
          <profile-complaint :staff_id="staff.id"></profile-complaint>
          <!-- end card Жалобы -->

          <additional-work :staff_id="staff.id"></additional-work>
      </div>
      <div v-else>
          <div class="page404">
            <h2 class="text404">
              <!-- 4<span>0</span>4 -->
              <img  src="/img/gohrnew.png" alt=""/>
            </h2>
          </div>
      </div>
      <el-drawer
        title="I'm outer Drawer"
        :visible.sync="showStaffAttendance"
        size="100%"
        :with-header="false"
        :with-footer="false"
        ref="show-tms"
        @opened="drawerStaffAttendanceOpened('show-staff-attendance')">
        <div>
          <show-staff-attendance
            :staff="selected"
            @closeStaffAttendanceModal="closeStaffAttendanceModal"
            :child="child"
            ref="show-staff-attendance"
          ></show-staff-attendance>
        </div>
      </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import staffBonuses from "../profile/tabs/components/staff-bonuses.vue";
import staffPenalties from "../profile/tabs/components/staff-penalties.vue";
import AdditionalWork from "../profile/tabs/components/additionalWork.vue";
import ProfileTakeAssent from "../profile/tabs/components/profileTakeAssent.vue";
import AdvanceSalary from "../profile/tabs/components/advanceSalary.vue";
import ProfileComplaint from "../profile/tabs/components/profileComplaint.vue";
import showStaffAttendance from '../profile/tabs/components/show-staff-attendance.vue';
import axios from "axios";
import moment from 'moment';

import { i18n } from "@/utils/i18n";
export default {
  name: "applicationCandidates",
  components: {
        staffBonuses,
        staffPenalties,
        AdditionalWork,
        AdvanceSalary,
        ProfileComplaint,
        ProfileTakeAssent,
        showStaffAttendance
    },
    watch: {
        select_date: {
          handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.table();
                }
            },
            deep: true,
            immediate: true,
        }

    },
  data() {
      return {
          select_date : moment().format("YYYY-MM-DD"),
          additionals:{},
          advance_salaries:{},
          waiti:0,
          deny:0,
          accept:0,
          selected:null,
          staffCheck:false,
          loadProfile:false,
          loadingData:false,
          loadClientRequirements:false,
          staffUuid: this.$route.params.staffUuid,
          baseUrl: process.env.VUE_APP_URL.substring(
                0,
                process.env.VUE_APP_URL.length - 3
              ),
          daily_reports:{},
          child: {},
          work_hour:null,
          loadContact:false,
          options : {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          showStaffAttendance:false,
          pickerOptions: {
            disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e7;
                    }
        }
      };
  },
  created() {
      this.fetchStaff();
      this.table();
  },
  computed: {
      ...mapGetters({
          staff: "openStaffProfile/model",
          staff_list: "openStaffProfile/staff", 
          columns: "openStaffProfile/columns",
          rules: "openStaffProfile/rules",
          staffContact: "openStaffProfile/staff_contact",
          staffClientRequirements: "openStaffProfile/staff_client_requirement",
          mode:"MODE"
      }),
  },

  methods: {
    ...mapActions({
      show: "openStaffProfile/show",
      updateList: "openStaffProfile/oneStaff",
      acquaintedRequirement: "openStaffProfile/acquaintedRequirement",
      getStaffContact: "openStaffProfile/getStaffContact",
      getStaffClientRequirements: "openStaffProfile/getStaffRequirement",
    }),
    showRequirement(param){
      console.log(param)
      var query ={
                'uuid':this.$route.params.staffUuid
              }
      this.acquaintedRequirement(query)
        .then((res)=>{
          this.fetchStaff();
        }).catch((err) => {
            this.loadProfile = false;
            this.staffCheck =false;
          });
    },
    fetchStaff() {
      if (!this.loadProfile && this.$route.params.staffUuid) {
        this.loadProfile = true;
        this.show(this.$route.params.staffUuid)
          .then((res) => {
            this.work_hour = res.data.result.data.staff.work_mounth_plane.limit.work_time_this_day
            this.staffCheck =true;
            this.loadProfile = false;
            this.fetchStaffContact();
            this.fetchStaffClientRequirements();
          
          })
          .catch((err) => {
            this.loadProfile = false;
            this.staffCheck =false;
          });
      }
    },
    fetchStaffContact() {
      if ( !this.loadContact&&this.staff && this.staff.id) {
        this.loadContact = true;
        this.getStaffContact(this.staff.id)
          .then((res) => {
            this.loadContact = false;
          })
          .catch((err) => {
            this.$alert(err);
            this.loadContact = false;
          });
      }
    },
    fetchStaffClientRequirements() {
      if (!this.loadClientRequirements && this.staff && this.staff.id) {
        console.log(this.staff.id)
        this.loadClientRequirements = true;
        this.getStaffClientRequirements(this.staff.id)
          .then((res) => {
            this.loadClientRequirements = false;
          })
          .catch((err) => {
            this.loadClientRequirements = false;
          });
      }
    },
    table(){
            const today = new Date();
            var nur = new Date(today.getFullYear(), today.getMonth(), 1)
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            this.firstDay = nur.getDay();
              var query ={
                'uuid':this.$route.params.staffUuid,
                'select_date':this.select_date
              }
            this.updateList(query).then((res) => {
              console.log(this.staff_list);
              
            });
        },

    showStaffAttendanceInfo(report) {
      console.log('salom')
      this.child.staff_id = report.staff_id;
      this.child.date = report.date;
      this.showStaffAttendance = true;
    },
    closeStaffAttendanceModal(val) {
      this.showStaffAttendance = val;
    },
    drawerStaffAttendanceOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
    formatMonthDate(value){
      if (value) {
        return moment((value)).lang("ru").format('MMMM YYYY')
      }
    }
  },
};
</script>
<style lang="scss">
.link-mobil {
  display: none;
}
.block_1,
.block_2 {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  position: relative;
}
.logins {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bekmega {
  width: 200px;
}
.gohr {
  width: 150px;
}
.bg-cwe {
  background-color: #dae6e9;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}
.container-my {
  width: 1500px;
  margin: auto;
}
@media (max-width: 1200px) {
  .container-my {
      width: 100%;
      margin: auto;
  }
}

@media (max-width: 500px) {
  .container-my {
      width: 100%;
      margin: auto;
  }
  /* .logins.text-center {
      flex-direction: column;
  }
  .logins.text-center {
      flex-direction: column;
  } */
  .bekmega {
      width: 100px;
  }
  .link-mobil {
      width: 100%;
      background: #00c200;
      display: inline-block;
      color: #fff;
      top: -18px;
      position: absolute;
      right: 0px;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
  }
}
@media (max-width: 320px) {
  .container-my {
      width: 100%;
      margin: auto;
  }
}
  .my-calendar_two{
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 1.5px;
  
  }
  .no_working {
    background-color: #2d2e2c7d !important;
    color: #fff;
  }
  .night_drawer{
    .el-drawer__body{
      background-color: #0f0e1e !important;
      overflow-x: scroll;
      overflow-y: scroll;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }  
  }
  .completion_percentage{
    position: absolute;
    top: 7px;
    right: 10px;
    display: inline-flex;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 800;
    z-index: 99;
  }
  
  @media only screen and (max-width: 600px){
      .cardinto__day{
        .el-card__body{
          padding: 20px !important;
          flex-direction: column !important;
        }
      }
      .bg-cwe{
        padding: 10px !important;
      }
  }
  .page404{
    position: relative;
    width: 100%;
    height: 100vh;
    .text404{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 100px;
      color: #0d67c9;
      font-weight: 900 !important;
      font-family: "M-Bold";
      margin: 0;
      span{
        color: #399919;
      }
    }
  }
  </style>
  